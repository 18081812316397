<template>
    <div class="rank">
        <img src="/year-report" alt="">
        <button v-if="isWechat" class="download">长按保存2024年度战绩</button>
        <button v-else class="download" @click="download">保存2024年度战绩</button>

        <img v-if="isWechat" src="/year-report" class="wechat">
    </div>
</template>

<script>
import axios from "axios";

export default {
    name: "layout",
    computed: {
        isWechat: function () {
            console.log(window.navigator.userAgent);
            return window.navigator.userAgent.toLowerCase().indexOf('micromessenger') !== -1
        }
    },
    mounted() {
    },
    methods: {
        download() {
            axios.post('/year-report', {});

            var image = new Image();
            image.setAttribute("crossOrigin", "anonymous");
            var _this = this;
            image.onload = function () {
                var canvas = document.createElement("canvas");
                canvas.width = image.width;
                canvas.height = image.height;
                var context = canvas.getContext("2d");
                context.drawImage(image, 0, 0, image.width, image.height);
                var url = canvas.toDataURL("image/jpg"); //得到图片的base64编码数据
                var a = document.createElement("a"); // 生成一个a元素
                var event = new MouseEvent("click"); // 创建一个单击事件
                a.download = "year-report.jpg"; // 设置图片名称
                a.href = url; // 将生成的URL设置为a.href属性
                a.dispatchEvent(event); // 触发a的单击事件
            };
            image.src = '/year-report'
        },
    }

}
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
@import '../../styles/mixins.styl'
.rank
    width 100vw
    height auto
    position relative

    img
        width 100%

    .download
        border none
        border-radius 40px
        background #2faee5
        color #fff
        font-size 46px
        fontFamily()
        position absolute
        width 85vw
        height 100px
        top 80%
        left 10vw
.wechat{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    opacity: 0;
    z-index: 20;
}
</style>
